.navbarMain{ 
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2; 
  height: auto;   
  transition: 300ms; 
}
.navbarMain--hidden{
  transform: translateY(-127px);
  transition: 2000ms; 
}
#navbarMenu{
    display: block;
}
.navMenuBtn{
display: none;
}
#offcanvasNavbar{
    display: none;
}
.nav-link{
  cursor: pointer;
}
/*---------- all page--------- */
.allPage{
  margin-top: 100px;
}
/* ---------home start ---------- */

/* .homeTopCarousel{
    width: 100%;
    height: 75vh;
} */
.homeGameCard{
    width: 270px;
    /* border: 1px solid; */
    border-radius:38px 38px 8px 8px ;
}
.ourFavoritGame {
    position: relative;
    /* width: 163px; */
  }
  .ourFavoritImage {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .ourFavoritMiddle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .ourFavoritGame:hover .ourFavoritImage {
    filter: blur(5px);
   
  }
  
  .ourFavoritGame:hover .ourFavoritMiddle {
    opacity: 2; 
  }
  .ourFavoritGameSection .carousel-root .carousel  >.control-dots{
  transform: rotateZ(90deg);
  top: 50% !important;
  right:-20px !important;
  width: fit-content;
  height: fit-content; 

  }
  .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px)!important;
}
.howItsWork{
  position: relative !important;
  height: 600px; 
}
.howItsWork1{
  position: absolute !important;
  right: 10%;
  top:-13.5%;
}
.howItsWork2{
  position: absolute !important;
  right: 20%;
  top: 10%;
}
.howItsWork3{
  position: absolute;
  right: 30%;
  top: 33.5%;
}
.svgDrow{
  clip-path: inset(0 100% 0 0);
  transition: clip-path 3000ms ease-out;

}
.svgDrow.active{
  clip-path: inset(0 0 0 0); 
}
.hiwA{
  display:none ;
}
.hiwA.active{
  display: block;
}
/* result page start  */
.resultHedButton{
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center; 
  border-radius: 30px 30px 0 0;
  cursor: pointer;
} 
@media(max-width:1300px){
  .howItsWork{
    position: relative !important; 
   margin-top: 100px; 
  }
}

@media(max-width:990px){
    #navbarMenu{
        display: none !important;
    }
    .navMenuBtn{
        display: block;
    }
    #offcanvasNavbar{
        display: block; 
    }
    /* -------home-------- */
    /* .homeTopCarousel{
        width: 100%;
        height: 55vh;
    } */
}
@media(max-width:650px){
  .steps3{
    display: none;
  }
}